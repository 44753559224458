<template>
  <action-panel :open="openAction" @action-closed="openAction=false">
    <template v-slot:title>Einsatz löschen?</template>
    <template v-slot:message>
      Der Einsatz und alles was damit geplant wurde wird unwiederbringbar gelöscht.<br>
      Möchtest du diesen Einsatz wirklich löschen?
    </template>
    <template v-slot:buttons>
      <button type="button" @click="openAction = false" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">Abbrechen</button>
      <button type="button" @click="destroyEvent(); openAction = false" class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:w-auto sm:text-sm">Löschen</button>
    </template>
  </action-panel>
  <modal-component :open="showStepsModal" @modal-closed="showStepsModal = false; modalClosed">
    <template v-slot:title><span>Anmelden für den Dienst</span></template>
    <template v-slot:description>Hier kannst du dich für eine Schicht anmelden oder wieder rausnehmen</template>
    <template v-slot:content>
      <div class="space-y-6 pt-6 pb-5">
        <div v-if="authStore.hasPermission('delete_events')">
          <label class="text-red-400">Einsatz löschen</label>
          <div>
            <button @click="openAction=true; idToDestroy=evt.id; showStepsModal=false" class="rounded-md border border-gray-300 bg-red-600 py-2 px-4 text-sm font-medium text-gray-200 shadow-sm hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">Löschen</button>
          </div>
        </div>
        <h2 class="text-lg font-medium text-black dark:text-gray-100">Schichten</h2>
        <fieldset class="border-t border-b border-gray-200 dark:border-slate-600">
          <legend class="sr-only">Schichten</legend>
          <div class="divide-y divide-gray-200 dark:divide-slate-600">
            <div class="mt-3">
              <label class="dark:text-gray-100">Eintragen für:</label>
              <Listbox as="div" v-model="selectedUser">
                <div class="mt-1 relative text-black dark:text-gray-100">
                  <ListboxButton class="bg-white dark:bg-slate-600 relative w-full border border-gray-300 dark:border-slate-600 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-teal-500 focus:border-teal-500 sm:text-sm">
                    <span class="block truncate">{{ selectedUser.name }}, {{ selectedUser.first_name }}</span>
                    <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
                  </ListboxButton>

                  <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                    <ListboxOptions class="absolute z-10 mt-1 w-full bg-white dark:bg-slate-700 shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                      <ListboxOption as="template" v-for="user in users" :key="user.id" :value="user" v-slot="{ active, selected: selectedUser }">
                        <li :class="[active ? 'text-white bg-teal-600' : 'text-gray-900 dark:text-gray-300', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                          <span :class="[selectedUser ? 'font-semibold' : 'font-normal', 'block truncate']">
                            {{ user.name }}, {{ user.first_name }}
                          </span>
                          <span v-if="selectedUser" :class="[active ? 'text-white' : 'text-teal-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                          </span>
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </div>
              </Listbox>
            </div>
            <div v-for="step in evt.steps" :key="step.id" class="relative flex items-start py-4">
              <div class="min-w-0 flex-1 text-sm">
                <label :for="`step-${step.id}`" class="font-medium text-gray-700 dark:text-gray-300">{{ moment(step.startDate).format('HH:mm') }} - {{ moment(step.endDate).format('HH:mm') }}</label>
                <p id="comments-description" class="text-gray-500">
                  <span v-for="user in step.users" :key="user.id">{{ user.first_name }} {{ user.name }},</span>
                </p>
              </div>
              <div class="ml-3 flex items-center h-5">
                <input v-if="step.users.length < maxUsers || step.users.some(x => x.id === selectedUser.id)" :id="`step-${step.id}`" v-model="selectedTimes" :value="step.id" :name="`step-${step.id}`" aria-describedby="comments-description" type="checkbox" class="focus:ring-teal-500 h-4 w-4 text-teal-600 border-gray-300 rounded dark:bg-slate-700" />
              </div>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="flex flex-shrink-0 justify-end px-4 py-4">
        <button type="button" class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2" @click="showStepsModal = false; modalClosed">Abbrechen</button>
        <button type="button" @click="saveStep" class="ml-4 inline-flex justify-center rounded-md border border-transparent bg-teal-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2">Speichern</button>
      </div>
      <hr>
    </template>
  </modal-component>
</template>
<script setup>
import {defineProps, defineEmits, ref, watch} from "vue";
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid'
import moment from "moment";
import {fetchWrapper} from "@/helpers";
import ModalComponent from './ModalComponent'
import ActionPanel from "@/components/ActionPanel";
import {notify} from "notiwind";
import {useAuthStore} from "@/stores";

const props = defineProps({
  'open': Boolean,
  'event': Object,
  //'selected': Array
});
const selectedTimes = ref([]);
const evt = ref(props.event);
const showStepsModal = ref(props.open);
const users = ref([])
const authStore = useAuthStore()
const selectedUser = ref(authStore.user);
const openAction = ref(false);
const idToDestroy = ref(false);
const maxUsers = ref(4);

const emit = defineEmits(['modal-closed','event-destroyed'])

watch(() => props.open, () => {
  showStepsModal.value = props.open;
})
watch(() => props.event, async () => {
  evt.value = props.event;
  users.value = await fetchWrapper.get('users/public/' + evt.value.id);
  selectedUser.value = users.value.find(x => x.id === selectedUser.value.id);
  selectedTimes.value = selectedUser.value.event_user.map(x => x.pm_event_step_id);
  if(props.event.pm_item_id === 1) {
      console.log(props.event.pm_item_id);
      console.log(props.event);
      if (moment(props.event.startDate) > moment('2023-04-19') && moment(props.event.startDate) < moment('2023-05-20')) {
          maxUsers.value = 3;
      }
  }
}, {deep: true})

watch(() => selectedUser.value, () => {
  selectedTimes.value = selectedUser.value.event_user.map(x => x.pm_event_step_id);
})

async function saveStep(){
  evt.value = await fetchWrapper.post('steps/apply/' + evt.value.id,{ ids: selectedTimes.value, customUser: selectedUser.value.id });
  notify({
    group: "success",
    title: "Yeah!",
    text: "Deine auswahl wurde gespeichert!"
  }, 4000
  )
}

async function destroyEvent(){
  await fetchWrapper.delete("events/" + idToDestroy.value);
  emit('event-destroyed')
  notify({
    group: "success",
    title: "Yeah!",
    text: "Der Einsatz wurde gelöscht"
  })
}

watch(() => showStepsModal.value, () => {
  if(showStepsModal.value === false){
    emit("modal-closed");
  }
})

function modalClosed(){
  emit("modal-closed");
}
</script>